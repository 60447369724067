<template>
  <div
    @click="toggleZoom"
    :style="{ width: imageWidth + 'px', height: imageHeight + 'px' }"
    class="img-fluid"
  >
    <Frame :pose="pose" class="frame" />
    <ZoomImage :pose="pose" :src="src" />
  </div>
</template>

<script>
import posed from "vue-pose";

export default {
  components: {
    ZoomImage: posed.img({
      zoomedOut: {
        position: "static",
        width: "auto",
        height: "auto",
        flip: true,
        duration: 400,
        ease: [0.16, 0.38, 0.46, 0.77],
      },
      zoomedIn: {
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        flip: true,
        duration: 400,
        ease: [0.16, 0.38, 0.46, 0.77],
      },
    }),
    Frame: posed.div({
      zoomedIn: {
        applyAtStart: { display: "block" },
        opacity: 1,
      },
      zoomedOut: {
        applyAtEnd: { display: "none" },
        opacity: 0,
      },
    }),
  },
  props: ["imageWidth", "imageHeight", "src"],
  data: () => ({
    isZoomed: false,
  }),
  methods: {
    zoomIn() {
      window.addEventListener("scroll", this.zoomOut);
      this.isZoomed = true;
    },
    zoomOut() {
      window.addEventListener("scroll", this.zoomOut);
      this.isZoomed = false;
    },
  },
  computed: {
    toggleZoom() {
      return this.isZoomed ? this.zoomOut : this.zoomIn;
    },
    pose() {
      return this.isZoomed ? "zoomedIn" : "zoomedOut";
    },
  },
};
</script>

<style scoped>
img {
  display: block;
  max-width: 100%;
  margin: auto;
}
.frame {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  background: rgba(0, 0, 0, 0.5);
  transform: translateZ(0);
  /* transition: ease-in-out 0.2; */
}
</style>